import React, { MouseEvent, useState } from 'react';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ReportCategory } from 'src/types/reportCategory';
import DropdownSelect from 'src/components/formElements/DropdownSelect/DropdownSelect';
import { convertNameAndCodeToLabelAndValue } from 'src/utils/typeConversions';
import FormField from 'src/components/formElements/FormField/FormField';
import Icon from 'src/components/icons/Icon/Icon';
import 'src/components/AddReportCategoryDropdownSelect/AddReportCategoryDropdownSelect.css';
import DatasetValidationForm from "../DatasetValidationForm/DatasetValidationForm";

export interface AddReportCategoryDropdownSelectProps extends FunctionComponentProps {
    reportCategories: ReportCategory[];
    selectedReportCategories: ReportCategory[] | null;
    onChange: (reportCategories: ReportCategory[]) => void;
}

function getReportCategoryByCode(
    reportCategories: ReportCategory[] | null,
    reportCategoryCode: string
): ReportCategory | undefined {
    if (!reportCategories || !reportCategories.length) {
        return;
    }

    return reportCategories.find((x: ReportCategory): boolean => {
        return x.code === reportCategoryCode;
    });
}

function getUnselectedReportCategories(
    reportCategories: ReportCategory[],
    selectedReportCategories: ReportCategory[] | null
): ReportCategory[] {
    if (!selectedReportCategories || !selectedReportCategories.length) {
        return reportCategories;
    }

    return reportCategories.filter((reportCategory: ReportCategory) => {
        return !Boolean(selectedReportCategories.find((value: ReportCategory) => {
            return value.code === reportCategory.code;
        }));
    });
}

function AddReportCategoryDropdownSelect(
    {
        reportCategories,
        selectedReportCategories,
        onChange
    }: AddReportCategoryDropdownSelectProps
): FunctionComponentReturnType {
    const [reportCategoryCode, setReportCategoryCode] = useState<string>('');
    const [showValidatePopup, setShowValidatePopup] = useState<boolean>(false);

    function addReportCategory(): void {
        if (getReportCategoryByCode(selectedReportCategories, reportCategoryCode)) {
            return;
        }

        const targetReportCategory: ReportCategory | undefined =
            getReportCategoryByCode(reportCategories, reportCategoryCode);

        if (targetReportCategory) {
            onChange((selectedReportCategories || []).concat(targetReportCategory));
        }
    }

    function handleAddReportClick(event: MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        setShowValidatePopup(true);
    }

    return (
        <FormField>
            <DatasetValidationForm
                setShowValidatePopup={setShowValidatePopup}
                showValidatePopup={showValidatePopup}
                setReportCategoryCode={setReportCategoryCode}
                addReportCategory={addReportCategory}
                reportCategoryCode={reportCategoryCode}
            />

            <div className="report-category-dropdown-select-wrapper">
                <DropdownSelect
                    className="report-category-dropdown-select"
                    label="Report Categories"
                    options={
                        getUnselectedReportCategories(reportCategories, selectedReportCategories)
                            .map(convertNameAndCodeToLabelAndValue)
                    }
                    value={reportCategoryCode}
                    onChange={setReportCategoryCode}
                />
                <div className="add-report-category-icon">
                    <Icon
                        type="PlusFilled"
                        onClick={handleAddReportClick}
                        alt={'Add Report Category'}
                    />
                </div>
            </div>
        </FormField>
    );
}

export default AddReportCategoryDropdownSelect;
